.footer{
    width: 100%;
    padding: 2rem 0;
    background-color: #f8f8f8;
}

.footer .container{
    display: grid;
    grid-template-columns: repeat(4 , 1fr);
    gap: 1.2rem;
}
.footer .col{
    margin: auto;
}
footer h3{
    margin: .8rem 0;
}

.footer .social{
    text-align: center;
}
.footer .icon{
    font-size: 2rem;
    margin: 2rem 3rem;
    color: #5651e5;
}
@media screen and (max-width:940px){
    .footer .comntainer{
        grid-template-columns: repeat(2 , 1fr);
        gap: .5rem;
    }
    .footer .footer.icon{
        margin: 1rem;
    }
    
}
.body {
    background-color: rgba(0,0,0,.5);
    color: #ffff;
    height: 80vh;
    width: 100%;
    position: relative;
    margin-bottom: 4rem;
}

.body:before {
    content: '';
    { /*background: url('../../assets/im1.jpg') no-repeat center center/cover;*/}
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    
}
.body .content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 700px;
    margin: auto;
    padding: 1rem;
}

.body h1{
    font-size: 3.5rem;
    text-transform: capitalize;

}

.body .search-text{
    margin: 1.5rem;
    font-size: 1.4rem;
}
.search{
    display: flex;
    background-color: #fff;
    color: #333;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 8px;
}

.search input[type=text]:focus {
    outline: none;

}
.search label{
    padding: 0 14px 0 8px;
}

.search .radio {
    display: flex;
    align-items: center;
}

.search button{
    background-image: linear-gradient(45deg, #5651e5 0%, #709dff 100%);
    border: 1px solid #5651e5;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
}

@media screen and (max-width:940px) {
    .body {
        height: 70vh;

    }
    .body h1{
        font-size: 2.5rem;
    }
    .search input[type=text] {
        width: 100%;
    }
}














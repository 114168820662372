
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
  color: #333;
}


body {
  margin: 0;
  font-family: 'Raleway', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container{
  max-width: 1240px;
  margin: auto; 
}

.btn{
  color: #fff;
  margin: 0;
  padding: 2px ,4px;
  background-image: linear-gradient(45deg, #5651e5 0%,#709dff 100% );
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  
  transition: 0.4s;
  cursor: pointer;
}
.btn:active{
  transform: translate(4px);
  box-shadow: 0px  0px  1px  rgba(0, 0, 0, 0,0.2);
  border-bottom: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
